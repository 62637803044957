<template>
  <div class="convenzioni-list-container">
    <v-container class="relative" v-if="isEmployee">
      <h1>Convenzioni Tigros</h1>

      <v-row>
        <v-col cols="0" md="4" v-if="!$vuetify.breakpoint.mobile">
          <h3 class="convenzioni-title">FILTRA LE CONVENZIONI PER:</h3>
          <convenzioni-category />
        </v-col>
        <v-col
          cols="12"
          :md="$vuetify.breakpoint.mobile ? '12' : '8'"
          class="position-relative"
        >
          <div v-if="convenzioniData">
            <v-expansion-panels class="convenzioni-list" accordion flat>
              <v-expansion-panel
                v-for="item in convenzioniData"
                :key="item.id"
                class="mb-1"
              >
                <v-expansion-panel-header class="convenzioni-panel-header">
                  <h4 v-html="item.title.rendered" @click="clicked"></h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-html="item.content.rendered"
                    @click="clicked"
                    class="convenzioni-panel-content"
                  ></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <v-speed-dial
            v-if="$vuetify.breakpoint.mobile"
            v-model="fab"
            :right="true"
            :bottom="true"
            absolute
            direction="left"
            transition="slide-y-reverse-transition"
            class="convenzioni-filter"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="primary" fab>
                <v-icon v-if="fab">
                  $close
                </v-icon>
                <v-icon v-else>
                  $menu
                </v-icon>
              </v-btn>
            </template>
            <v-card><ConvenzioniCategory /> </v-card>
          </v-speed-dial>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="relative" v-else>
      <v-row>
        <v-col cols="12" md="7">
          <h1>Convenzioni Tigros</h1>
          <h2>Area Riservata Dipendenti Tigros</h2>
          <h3>Per accedere a quest'area occorre essere loggati</h3>
        </v-col>
        <v-col cols="12" md="5">
          <Login />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.convenzioni-list-container {
  .convenzioni-title {
    color: $primary;
  }
  .position-relative {
    position: relative;
  }
  .convenzioni-panel-header {
    border: 1px var(--v-primary-base) solid;
    padding: 13px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 13px !important;
  }
  .convenzioni-panel-content,
  .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: var(--v-primary-base) !important;
  }
  .convenzioni-list {
    .v-expansion-panel-header {
      color: var(--v-primary-base) !important;
      font-weight: bold;
    }
  }
  .convenzioni-filter {
    .v-speed-dial__list {
      min-width: 400px;
      left: initial;
      right: 0;
    }
  }
}
</style>
<script>
import ConvenzioniCategory from "@/components/wordpress/ConvenzioniCategory.vue";
import CMSService from "~/service/cmService";
import clickHandler from "~/mixins/clickHandler";
import { mapGetters } from "vuex";
import Login from "@/components/Login.vue";

export default {
  name: "ConvenzioniList",
  components: { ConvenzioniCategory, Login },
  data() {
    return {
      convenzioniData: null,
      fab: false
    };
  },
  mixins: [clickHandler],
  mounted() {
    this.fetchConvenzioniList(this.$route.params.categoryName);
    // this.fetchPage("private_convenzioni");
    this.page = this.$route.params.page;
  },
  methods: {
    async fetchConvenzioniList(categoryName) {
      let res = await CMSService.getCustomPostByFilters(
        "private_convenzioni",
        [
          {
            filterName: "filter[private_cat_convenzioni]",
            filterValue: categoryName
          }
        ],
        100
      );
      this.convenzioniData = res.data;
    }
  },

  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    isEmployee() {
      // If fidelityCard (login) starts with this preffix then is a Tigros employee
      return this.user?.login.substr(0, 7) === "0410557";
    }
  },
  watch: {
    "$route.params.categoryName": function(categoryName) {
      this.fetchConvenzioniList(categoryName);
    }
  }
};
</script>
